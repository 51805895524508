

export const BGBnners = [];

export const mostvieved = [
  {
    id: 1,
    duration: "2023 8 hr 40 min",
    movie: "../NewImg/sri-raghupati.png",
    logo: "../NewImg/dunky-removebg-preview.png",
    desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
    language: "",
    title: "Sri Raghupati",
    Portrays: "Thiruchitrambalam aka Pazham",
  },
  {
    id: 2,
    duration: "2023 8 hr 40 min",
    logo: "../NewImg/dunky-removebg-preview.png",
    desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
    title: "Sri Raghupati",
    movie: "../NewImg/assam-himal.jpeg",
    Portrays: "Shobana",
  },
  {
    id: 3,
    duration: "2023 8 hr 40 min",
    logo: "../NewImg/dunky-removebg-preview.png",
    desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
    title: "Sri Raghupati",
    movie: "../NewImg/assam3.webp",
    Portrays: "Rajeswari",
  },
  {
    id: 4,
    duration: "2023 8 hr 40 min",
    logo: "../NewImg/dunky-removebg-preview.png",
    desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
    title: "Sri Raghupati",
    movie: "../NewImg/assam4.jpg",
    Portrays: "Shobana",
  },
  {
    id: 5,
    duration: "2023 8 hr 40 min",
    logo: "../NewImg/dunky-removebg-preview.png",
    desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
    title: "Sri Raghupati",
    movie: "../NewImg/assam5.jpeg",
    Portrays: "Shobana",
  },
  {
    id: 6,
    duration: "2023 8 hr 40 min",
    logo: "../NewImg/dunky-removebg-preview.png",
    desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
    title: "Sri Raghupati",
    movie: "../NewImg/assam6.jpg",
    Portrays: "Shobana",
  },
  {
    id: 7,
    duration: "2023 8 hr 40 min",
    logo: "../NewImg/dunky-removebg-preview.png",
    desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
    title: "Sri Raghupati",
    movie: "../NewImg/assam7.jpg",
    Portrays: "Shobana",
  },
  {
    id: 7,
    duration: "2023 8 hr 40 min",
    logo: "../NewImg/dunky-removebg-preview.png",
    desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
    title: "Sri Raghupati",
    movie: "../NewImg/assam8.jpg",
    Portrays: "Thiruchitrambalam aka Pazham",
  },
  {
    id: 9,
    duration: "2023 8 hr 40 min",
    logo: "../NewImg/dunky-removebg-preview.png",
    desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
    title: "Sri Raghupati",
    movie: "../NewImg/assam9.jpg",
    Portrays: "Thiruchitrambalam aka Pazham",
  },
];
export const mostvieved2 = [
  {
    id: 1,
    duration: "2023 8 hr 40 min",
    movie: "../NewImg/sri-raghupati.png",
    logo: "../NewImg/dunky-removebg-preview.png",
    desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
    language: "",
    title: "Sri Raghupati",
  },
  {
    id: 2,
    duration: "2023 8 hr 40 min",
    logo: "../NewImg/dunky-removebg-preview.png",
    desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
    title: "Sri Raghupati",
    movie: "../NewImg/assam-himal.jpeg",
  },
  {
    id: 3,
    duration: "2023 8 hr 40 min",
    logo: "../NewImg/dunky-removebg-preview.png",
    desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
    title: "Sri Raghupati",
    movie: "../NewImg/assam3.webp",
  },
  {
    id: 4,
    duration: "2023 8 hr 40 min",
    logo: "../NewImg/dunky-removebg-preview.png",
    desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
    title: "Sri Raghupati",
    movie: "../NewImg/assam4.jpg",
  },
  {
    id: 5,
    duration: "2023 8 hr 40 min",
    logo: "../NewImg/dunky-removebg-preview.png",
    desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
    title: "Sri Raghupati",
    movie: "../NewImg/assam5.jpeg",
  },
  {
    id: 6,
    duration: "2023 8 hr 40 min",
    logo: "../NewImg/dunky-removebg-preview.png",
    desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
    title: "Sri Raghupati",
    movie: "../NewImg/assam6.jpg",
  },
  {
    id: 7,
    duration: "2023 8 hr 40 min",
    logo: "../NewImg/dunky-removebg-preview.png",
    desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
    title: "Sri Raghupati",
    movie: "../NewImg/assam7.jpg",
  },
  {
    id: 7,
    duration: "2023 8 hr 40 min",
    logo: "../NewImg/dunky-removebg-preview.png",
    desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
    title: "Sri Raghupati",
    movie: "../NewImg/assam8.jpg",
  },
  {
    id: 9,
    duration: "2023 8 hr 40 min",
    logo: "../NewImg/dunky-removebg-preview.png",
    desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
    title: "Sri Raghupati",
    movie: "../NewImg/assam9.jpg",
  },
];
export const RecommendedMovies = [
  {
    id: 1,
    duration: "2023 8 hr 40 min",
    movie: "../NewImg/sri-raghupati.png",
    logo: "../NewImg/dunky-removebg-preview.png",
    desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
    language: "",
    title: "Sri Raghupati",
  },
  {
    id: 2,
    duration: "2023 8 hr 40 min",
    logo: "../NewImg/dunky-removebg-preview.png",
    desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
    title: "Sri Raghupati",
    movie: "../NewImg/assam-himal.jpeg",
  },
  {
    id: 3,
    duration: "2023 8 hr 40 min",
    logo: "../NewImg/dunky-removebg-preview.png",
    desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
    title: "Sri Raghupati",
    movie: "../NewImg/assam3.webp",
  },
  {
    id: 4,
    duration: "2023 8 hr 40 min",
    logo: "../NewImg/dunky-removebg-preview.png",
    desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
    title: "Sri Raghupati",
    movie: "../NewImg/assam4.jpg",
  },
  {
    id: 5,
    duration: "2023 8 hr 40 min",
    logo: "../NewImg/dunky-removebg-preview.png",
    desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
    title: "Sri Raghupati",
    movie: "../NewImg/assam5.jpeg",
  },
  {
    id: 6,
    duration: "2023 8 hr 40 min",
    logo: "../NewImg/dunky-removebg-preview.png",
    desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
    title: "Sri Raghupati",
    movie: "../NewImg/assam6.jpg",
  },
  {
    id: 7,
    duration: "2023 8 hr 40 min",
    logo: "../NewImg/dunky-removebg-preview.png",
    desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
    title: "Sri Raghupati",
    movie: "../NewImg/assam7.jpg",
  },
  {
    id: 7,
    duration: "2023 8 hr 40 min",
    logo: "../NewImg/dunky-removebg-preview.png",
    desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
    title: "Sri Raghupati",
    movie: "../NewImg/assam8.jpg",
  },
  {
    id: 9,
    duration: "2023 8 hr 40 min",
    logo: "../NewImg/dunky-removebg-preview.png",
    desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
    title: "Sri Raghupati",
    movie: "../NewImg/assam9.jpg",
  },
];
export const BGBnners2 = [
  {
    id: 0,
    Category: "Action",
    language: ["Assamese", "Hindi", "Marathi", "Kannada"],
    title: "Sri raghupati",
    logo: "../NewImg/rglogo.png",
    banner: "../assest/asambnr1 (1).jpeg",
    subTitel: "Yellow Board",
    desc: "A mysterious event ensnares a journalist in an unfortunate trap, thus unfolding the extraordinary tale of an ordinary taxi driver. A tale of love, friendship, betrayal, murder and human weakness.",
    duration: "2 h 25 min 2022",
    movivid:
      "https://s3.ll.videorolls.row.aiv-cdn.net/ww_iad/b40e/8beb/8b57/4a12-8beb-5d7cb41462c4/474b7e40-a21f-405b-bb2e-3a4dc7a2343b_video_480p_900kbps_audio_aaclc_128kbps.mp4",
  },
  {
    id: 1,

    Category: "Action",
    language: ["Assamese", "Hindi", "Marathi", "Kannada"],
    title: "Salar",
    banner: "../assest/asambnr1 (2).jpeg",
    logo: "../NewImg/salrlg.png",
    info: "2023 movie ",
    subTitel: "Yellow Board",
    desc: "A mysterious event ensnares a journalist in an unfortunate trap, thus unfolding the extraordinary tale of an ordinary taxi driver. A tale of love, friendship, betrayal, murder and human weakness.",
    duration: "2 h 25 min 2022",
    movivid:
      "https://s3.ll.videorolls.row.aiv-cdn.net/ww_iad/a535/24b0/e3de/4b57-ba56-fae476526c86/95c59aca-e41c-4cd7-b158-b01893496a97_video_480p_900kbps_audio_aaclc_128kbps.mp4",
  },
  {
    id: 2,

    Category: "Action",
    language: ["Assamese", "Hindi", "Marathi", "Kannada"],
    title: "Tiger",
    banner: "../assest/asambnr1 (3).jpeg",
    logo: "../NewImg/tiger3.jpg",
    info: "2023 movie ",
    subTitel: "Yellow Board",
    desc: "A mysterious event ensnares a journalist in an unfortunate trap, thus unfolding the extraordinary tale of an ordinary taxi driver. A tale of love, friendship, betrayal, murder and human weakness.",
    duration: "2 h 25 min 2022",
    movivid:
      "https://s3.ll.videorolls.row.aiv-cdn.net/ww_iad/f190/b8e7/3e76/4b42-914a-7a9ca3cb3f23/f549b19d-f2e8-413d-8a8e-013127b94e03_video_480p_900kbps_audio_aaclc_128kbps.mp4",
  },

  {
    id: 4,

    Category: "Action",
    language: ["Assamese", "Hindi", "Marathi", "Kannada"],
    title: "#6th in india",
    banner: "../assest/asambnr1 (4).jpeg",
    logo: "../NewImg/gangalg.png",
    info: "2023 movie ",
    subTitel: "Yellow Board",
    desc: "A mysterious event ensnares a journalist in an unfortunate trap, thus unfolding the extraordinary tale of an ordinary taxi driver. A tale of love, friendship, betrayal, murder and human weakness.",
    duration: "2 h 25 min 2022",
    movivid:
      "https://s3-iad-2.cf.trailer.row.aiv-cdn.net/97da/e9a0/9a90/45d8-b692-7a2d0c9fdc15/bb12e4b4-72a4-4447-b8de-9ba9b80742c5_video_900_audio_aaclc_128.mp4?Expires=1703726496&Signature=Y3JUdTJg-GP8Tdew6QbWWf-v1CtciHyc4Zubr7CcL3plXrXpzUb1itpzlAudxVg4hPYGm2R9YuHz4Qt8pPxzsyimxb0lWmdt~vfPXWLpbKObtcxzMLzPxmq7dpj14si5ZZ8TVjgVuzM1Tmsq1AAjPg9g7Mst-QMnnN1iLVYo~ZOfMpwYJjFtG-G2lVGbhFfElUfOqz2A8pwVVMTr3FNJl~pueWm3GWM6X9waqbt4oCsEOg84Adq3QEvxGec2j23Xe75val6R-8SecjLPbNRMgrfMzdo4fF6EDVgK491yv60SLXC5AiStwV50MA-fbaYcda8aoi98AI6WaR8a3KQavw__&Key-Pair-Id=APKAJIYEUF5P2E3CCYTA",
  },
  {
    id: 5,

    Category: "Action",
    language: ["Assamese", "Hindi", "Marathi", "Kannada"],
    title: "Sri raghupati",
    logo: "../NewImg/rglogo.png",
    banner: "../assest/asambnr1 (5).jpeg",
    subTitel: "Yellow Board",
    desc: "A mysterious event ensnares a journalist in an unfortunate trap, thus unfolding the extraordinary tale of an ordinary taxi driver. A tale of love, friendship, betrayal, murder and human weakness.",
    movivid:
      "https://s3-iad-2.cf.trailer.row.aiv-cdn.net/97da/e9a0/9a90/45d8-b692-7a2d0c9fdc15/bb12e4b4-72a4-4447-b8de-9ba9b80742c5_video_900_audio_aaclc_128.mp4?Expires=1703726496&Signature=Y3JUdTJg-GP8Tdew6QbWWf-v1CtciHyc4Zubr7CcL3plXrXpzUb1itpzlAudxVg4hPYGm2R9YuHz4Qt8pPxzsyimxb0lWmdt~vfPXWLpbKObtcxzMLzPxmq7dpj14si5ZZ8TVjgVuzM1Tmsq1AAjPg9g7Mst-QMnnN1iLVYo~ZOfMpwYJjFtG-G2lVGbhFfElUfOqz2A8pwVVMTr3FNJl~pueWm3GWM6X9waqbt4oCsEOg84Adq3QEvxGec2j23Xe75val6R-8SecjLPbNRMgrfMzdo4fF6EDVgK491yv60SLXC5AiStwV50MA-fbaYcda8aoi98AI6WaR8a3KQavw__&Key-Pair-Id=APKAJIYEUF5P2E3CCYTA",
  },

  {
    id: 6,

    Category: "Action",
    language: ["Assamese", "Hindi", "Marathi", "Kannada"],
    title: "Salar",
    banner: "../assest/asambnr1 (6).jpeg",
    logo: "../NewImg/salrlg.png",
    info: "2023 movie ",
    subTitel: "Yellow Board",
    desc: "A mysterious event ensnares a journalist in an unfortunate trap, thus unfolding the extraordinary tale of an ordinary taxi driver. A tale of love, friendship, betrayal, murder and human weakness.",
    duration: "2 h 25 min 2022",
    movivid:
      "https://s3.ll.videorolls.row.aiv-cdn.net/ww_iad/b40e/8beb/8b57/4a12-8beb-5d7cb41462c4/474b7e40-a21f-405b-bb2e-3a4dc7a2343b_video_480p_900kbps_audio_aaclc_128kbps.mp4",
  },
  {
    id: 7,

    Category: "Action",
    language: ["Assamese", "Hindi", "Marathi", "Kannada"],
    title: "Tiger",
    banner: "../assest/hindibnr1 (1).webp",
    logo: "../NewImg/tiger3.jpg",
    info: "2023 movie ",
    subTitel: "Yellow Board",
    desc: "A mysterious event ensnares a journalist in an unfortunate trap, thus unfolding the extraordinary tale of an ordinary taxi driver. A tale of love, friendship, betrayal, murder and human weakness.",
    duration: "2 h 25 min 2022",
    movivid:
      "https://s3-iad-2.cf.trailer.row.aiv-cdn.net/97da/e9a0/9a90/45d8-b692-7a2d0c9fdc15/bb12e4b4-72a4-4447-b8de-9ba9b80742c5_video_900_audio_aaclc_128.mp4?Expires=1703726496&Signature=Y3JUdTJg-GP8Tdew6QbWWf-v1CtciHyc4Zubr7CcL3plXrXpzUb1itpzlAudxVg4hPYGm2R9YuHz4Qt8pPxzsyimxb0lWmdt~vfPXWLpbKObtcxzMLzPxmq7dpj14si5ZZ8TVjgVuzM1Tmsq1AAjPg9g7Mst-QMnnN1iLVYo~ZOfMpwYJjFtG-G2lVGbhFfElUfOqz2A8pwVVMTr3FNJl~pueWm3GWM6X9waqbt4oCsEOg84Adq3QEvxGec2j23Xe75val6R-8SecjLPbNRMgrfMzdo4fF6EDVgK491yv60SLXC5AiStwV50MA-fbaYcda8aoi98AI6WaR8a3KQavw__&Key-Pair-Id=APKAJIYEUF5P2E3CCYTA",
  },
  {
    id: 8,

    Category: "Action",
    language: ["Assamese", "Hindi", "Marathi", "Kannada"],
    title: "#6th in india",
    banner: "../assest/hindibnr1 (2).webp",
    logo: "../NewImg/gangalg.png",
    info: "2023 movie ",
    subTitel: "Yellow Board",
    desc: "A mysterious event ensnares a journalist in an unfortunate trap, thus unfolding the extraordinary tale of an ordinary taxi driver. A tale of love, friendship, betrayal, murder and human weakness.",
    duration: "2 h 25 min 2022",
    movivid:
      "https://s3.ll.videorolls.row.aiv-cdn.net/ww_iad/b40e/8beb/8b57/4a12-8beb-5d7cb41462c4/474b7e40-a21f-405b-bb2e-3a4dc7a2343b_video_480p_900kbps_audio_aaclc_128kbps.mp4",
  },
];

export const BGBnners3 = [
  {
    id: 0,
    movivid: "../NewImg/vd1.mp4",
    Category: "Action",
    language: ["Assamese", "Hindi", "Marathi", "Kannada"],
    title: "Sri raghupati",
    logo: "../NewImg/rglogo.png",
    banner: "../assest/asambnr1 (1).jpeg",
    subTitel: "Mast Mein Rehne Ka",
    desc: "A mysterious event ensnares a journalist in an unfortunate trap, thus unfolding the extraordinary tale of an ordinary taxi driver. A tale of love, friendship, betrayal, murder and human weakness.",
    duration: "2 h 25 min 2022",
  },

  {
    id: 1,
    movivid: "../NewImg/vd2.mp4",
    Category: "Action",
    language: ["Assamese", "Hindi", "Marathi", "Kannada"],
    title: "Salar",
    banner: "../assest/asambnr1 (2).jpeg",
    logo: "../NewImg/salrlg.png",
    info: "2023 movie ",
    subTitel: "Mast Mein Rehne Ka",
    desc: "A mysterious event ensnares a journalist in an unfortunate trap, thus unfolding the extraordinary tale of an ordinary taxi driver. A tale of love, friendship, betrayal, murder and human weakness.",
    duration: "2 h 25 min 2022",
  },
  {
    id: 2,
    movivid: "../NewImg/vd3.mp4",
    Category: "Action",
    language: ["Assamese", "Hindi", "Marathi", "Kannada"],
    title: "Tiger",
    banner: "../assest/asambnr1 (3).jpeg",
    logo: "../NewImg/tiger3.jpg",
    info: "2023 movie ",
    subTitel: "Mast Mein Rehne Ka",
    desc: "A mysterious event ensnares a journalist in an unfortunate trap, thus unfolding the extraordinary tale of an ordinary taxi driver. A tale of love, friendship, betrayal, murder and human weakness.",
    duration: "2 h 25 min 2022",
  },

  {
    id: 4,
    movivid: "",
    Category: "Action",
    language: ["Assamese", "Hindi", "Marathi", "Kannada"],
    title: "#6th in india",
    banner: "../assest/asambnr1 (4).jpeg",
    logo: "../NewImg/gangalg.png",
    info: "2023 movie ",
    subTitel: "Mast Mein Rehne Ka",
    desc: "A mysterious event ensnares a journalist in an unfortunate trap, thus unfolding the extraordinary tale of an ordinary taxi driver. A tale of love, friendship, betrayal, murder and human weakness.",
    duration: "2 h 25 min 2022",
  },

  {
    id: 5,
    movivid: "../NewImg/vd1.mp4",
    Category: "Action",
    language: ["Assamese", "Hindi", "Marathi", "Kannada"],
    title: "Sri raghupati",
    logo: "../NewImg/rglogo.png",
    banner: "../assest/asambnr1 (5).jpeg",
    subTitel: "Mast Mein Rehne Ka",
    desc: "A mysterious event ensnares a journalist in an unfortunate trap, thus unfolding the extraordinary tale of an ordinary taxi driver. A tale of love, friendship, betrayal, murder and human weakness.",
    duration: "2 h 25 min 2022",
  },

  {
    id: 6,
    movivid: "../NewImg/vd2.mp4",
    Category: "Action",
    language: ["Assamese", "Hindi", "Marathi", "Kannada"],
    title: "Salar",
    banner: "../assest/asambnr1 (6).jpeg",
    logo: "../NewImg/salrlg.png",
    info: "2023 movie ",
    subTitel: "Mast Mein Rehne Ka",
    desc: "A mysterious event ensnares a journalist in an unfortunate trap, thus unfolding the extraordinary tale of an ordinary taxi driver. A tale of love, friendship, betrayal, murder and human weakness.",
    duration: "2 h 25 min 2022",
  },
  {
    id: 7,
    movivid: "../NewImg/vd3.mp4",
    Category: "Action",
    language: ["Assamese", "Hindi", "Marathi", "Kannada"],
    title: "Tiger",
    banner: "../assest/hindibnr1 (1).webp",
    logo: "../NewImg/tiger3.jpg",
    info: "2023 movie ",
    subTitel: "Mast Mein Rehne Ka",
    desc: "A mysterious event ensnares a journalist in an unfortunate trap, thus unfolding the extraordinary tale of an ordinary taxi driver. A tale of love, friendship, betrayal, murder and human weakness.",
    duration: "2 h 25 min 2022",
  },

  {
    id: 8,
    movivid: "",
    Category: "Action",
    language: ["Assamese", "Hindi", "Marathi", "Kannada"],
    title: "#6th in india",
    banner: "../assest/hindibnr1 (2).webp",
    logo: "../NewImg/gangalg.png",
    info: "2023 movie ",
    subTitel: "Mast Mein Rehne Ka",
    desc: "A mysterious event ensnares a journalist in an unfortunate trap, thus unfolding the extraordinary tale of an ordinary taxi driver. A tale of love, friendship, betrayal, murder and human weakness.",
    duration: "2 h 25 min 2022",
  },
];

export const LanguageWise = [
  {
    id: 1,
    duration: "2023 8 hr 40 min",
    movie: "../NewImg/pst1 (1).jpg",
    logo: "../NewImg/dunky-removebg-preview.png",
    desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
    language: "",
    title: "Sri Raghupati",
  },
  {
    id: 2,
    duration: "2023 8 hr 40 min",
    logo: "../NewImg/dunky-removebg-preview.png",
    desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
    title: "Sri Raghupati",
    movie: "../NewImg/pst1 (2).jpg",
  },
  {
    id: 3,
    duration: "2023 8 hr 40 min",
    logo: "../NewImg/dunky-removebg-preview.png",
    desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
    title: "Sri Raghupati",
    movie: "../NewImg/pst1 (3).jpg",
  },
  {
    id: 4,
    duration: "2023 8 hr 40 min",
    logo: "../NewImg/dunky-removebg-preview.png",
    desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
    title: "Sri Raghupati",
    movie: "../NewImg/pst1 (4).jpg",
  },
  {
    id: 5,
    duration: "2023 8 hr 40 min",
    logo: "../NewImg/dunky-removebg-preview.png",
    desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
    title: "Sri Raghupati",
    movie: "../assest/PST3.jpg",
  },
  {
    id: 6,
    duration: "2023 8 hr 40 min",
    logo: "../NewImg/dunky-removebg-preview.png",
    desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
    title: "Sri Raghupati",
    movie: "../assest/PST4.jpg",
  },
  {
    id: 7,
    duration: "2023 8 hr 40 min",
    logo: "../NewImg/dunky-removebg-preview.png",
    desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
    title: "Sri Raghupati",
    movie: "../assest/PST4.jpg",
  },
];

// export const ContentData = [
//   {
//     id: 1,
//     duration: "2023 8 hr 40 min",
//     movie:
//       "https://s3-dub-2.cf.trailer.row.aiv-cdn.net/8340/511b/16f2/460c-a174-4d5b623268db/c76e81f2-992a-49b5-a1b0-8a42d898f8f2_video_900_audio_aaclc_128.mp4?Expires=1703726496&Signature=hbPS4btp9zGSM-UTd9FCTvDixKeyITcex3t~iUeNpSWUaGHUXxJ8j6o53MBMHkgl1YcUoAa4NgktiLtqB6tjGteVLt65XHphZE~WN00tC1RSJ0cB3FILrXPL~NbDcmWqaoZ9kHJm-6NYGhljkJSU5vG7raFBwdQyEOKjYpsXtZeVyeZMkNti3BZCQLULQMKlU7Us2t7hbGMfv-si1g0lrvSmCsj56DVTq0SsVWP8E6doELeJBGPF-SF525IB5W6JBmOXl6DFyRx~0uUMWw4cyI8UCybvvAYrTXUAGLsGZq9iBdR6qSpcyDnyddFy1O--4cAoiVL3eag1lTTBf3cGaA__&Key-Pair-Id=APKAJIYEUF5P2E3CCYTA",
//     logo: "../NewImg/dunky-removebg-preview.png",
//     desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
//     language: "",
//     title: "Sri Raghupati",
//     moviebnr: "../video/bn1.webp",
//   },
//   {
//     id: 2,
//     duration: "2023 8 hr 40 min",
//     logo: "../NewImg/dunky-removebg-preview.png",
//     desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
//     title: "Sri Raghupati",
//     moviebnr: "../video/bn2.webp",
//     movie:
//       "https://s3-iad-2.cf.trailer.row.aiv-cdn.net/2efc/7e2b/ac21/4f71-8657-5e9fa8f316e6/fa3742d3-c4a2-46f7-b0d4-ee2e618ba80c_video_900_audio_aaclc_128.mp4?Expires=1703726496&Signature=ViLmijKjzcoXxCBhaCJMnkW6kle-JBLtPcxBlloyO1Kx3PHLoUUhedNW-~XZWBa5G5dCJa5BIXKXbCjP5AkZvl8pq6YmYYw~jozpHT~jCeAQLOY8lia-tBvgbd7mxapEmKhxZDfOFn2yDZYEmDO30mzqAlz2ZkDUxWRoBhqojALrV1MaUaq2x5wsJ~0khBs57aJCu93Uq6LZ89MgaByPy39RshWJNI~k0n2DG9rdUv3DKpfIHNgNU98TgsjeL4rC4r1qe7miIo67f7RTGh9wn2ygvJ5owIiqr7BqAdl3WRioVqFawFZ~qaf6fPd0vIzdLrEOv~kCI8U0ulwAxIunLw__&Key-Pair-Id=APKAJIYEUF5P2E3CCYTA",
//   },
//   {
//     id: 3,
//     duration: "2023 8 hr 40 min",
//     logo: "../NewImg/dunky-removebg-preview.png",
//     desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
//     title: "Sri Raghupati",
//     moviebnr: "../video/bn3.webp",
//     movie:
//       "https://s3-iad-2.cf.trailer.row.aiv-cdn.net/97da/e9a0/9a90/45d8-b692-7a2d0c9fdc15/bb12e4b4-72a4-4447-b8de-9ba9b80742c5_video_900_audio_aaclc_128.mp4?Expires=1703726496&Signature=Y3JUdTJg-GP8Tdew6QbWWf-v1CtciHyc4Zubr7CcL3plXrXpzUb1itpzlAudxVg4hPYGm2R9YuHz4Qt8pPxzsyimxb0lWmdt~vfPXWLpbKObtcxzMLzPxmq7dpj14si5ZZ8TVjgVuzM1Tmsq1AAjPg9g7Mst-QMnnN1iLVYo~ZOfMpwYJjFtG-G2lVGbhFfElUfOqz2A8pwVVMTr3FNJl~pueWm3GWM6X9waqbt4oCsEOg84Adq3QEvxGec2j23Xe75val6R-8SecjLPbNRMgrfMzdo4fF6EDVgK491yv60SLXC5AiStwV50MA-fbaYcda8aoi98AI6WaR8a3KQavw__&Key-Pair-Id=APKAJIYEUF5P2E3CCYTA",
//   },
//   {
//     id: 4,
//     duration: "2023 8 hr 40 min",
//     logo: "../NewImg/dunky-removebg-preview.png",
//     desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
//     title: "Sri Raghupati",
//     moviebnr: "../video/bn4.webp",
//     movie:
//       "https://s3.ll.videorolls.row.aiv-cdn.net/ww_iad/b40e/8beb/8b57/4a12-8beb-5d7cb41462c4/474b7e40-a21f-405b-bb2e-3a4dc7a2343b_video_480p_900kbps_audio_aaclc_128kbps.mp4",
//   },
//   {
//     id: 5,
//     duration: "2023 8 hr 40 min",
//     logo: "../NewImg/dunky-removebg-preview.png",
//     desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
//     title: "Sri Raghupati",
//     moviebnr: "../video/bn5.webp",
//     movie:
//       "https://s3.ll.videorolls.row.aiv-cdn.net/ww_iad/fe15/e45a/10be/42ba-893d-5cd958258071/8111016f-39a3-459d-8cbb-661f6da6f863_video_480p_900kbps_audio_aaclc_128kbps.mp4",
//   },
//   {
//     id: 6,
//     duration: "2023 8 hr 40 min",
//     logo: "../NewImg/dunky-removebg-preview.png",
//     desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
//     title: "Sri Raghupati",
//     moviebnr: "../video/bn6.webp",
//     movie:
//       "https://s3.ll.videorolls.row.aiv-cdn.net/ww_iad/f190/b8e7/3e76/4b42-914a-7a9ca3cb3f23/f549b19d-f2e8-413d-8a8e-013127b94e03_video_480p_900kbps_audio_aaclc_128kbps.mp4",
//   },

//   {
//     id: 7,
//     duration: "2023 8 hr 40 min",
//     logo: "../NewImg/dunky-removebg-preview.png",
//     desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
//     title: "Sri Raghupati",
//     moviebnr: "../video/bn7.webp",
//     movie:
//       "https://s3.ll.videorolls.row.aiv-cdn.net/ww_iad/a535/24b0/e3de/4b57-ba56-fae476526c86/95c59aca-e41c-4cd7-b158-b01893496a97_video_480p_900kbps_audio_aaclc_128kbps.mp4",
//   },
//   {
//     id: 8,
//     duration: "2023 8 hr 40 min",
//     logo: "../NewImg/dunky-removebg-preview.png",
//     desc: "Pandappa from remote village of mandy vowes to perform if the field are suidtable",
//     title: "Sri Raghupati",
//     moviebnr: "../video/bn8.webp",
//     movie:
//       "https://s3.ll.videorolls.row.aiv-cdn.net/ww_iad/b40e/8beb/8b57/4a12-8beb-5d7cb41462c4/474b7e40-a21f-405b-bb2e-3a4dc7a2343b_video_480p_900kbps_audio_aaclc_128kbps.mp4",
//   },
// ];
